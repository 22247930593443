<template>
  <div class="content">
    <div class="main">
      <service-and-support-nav :active-index="3" />
      <service-and-support-list
        @change="search_change"
        :active-id="id"
        :noActive="tags_id == ''"
      />
      <div class="mainView">
        <div v-if="tags_name" class="tag_name NotoSansTCMedium">
          {{ tags_name }}
        </div>
        <div class="mainBox" :class="tags_name != '' ? 'tag' : ''">
          <div class="leftView" :class="{ no: is_two }">
            <div class="item" v-for="(item, index) in tabList" :key="index">
              <span
                v-if="tags_id == ''"
                :class="subId == item.id ? 'active' : ''"
                @click="tabClick(item.id)"
                >{{ item.name }}</span
              >
              <span v-else @click="tabClick(item.id)">{{ item.name }}</span>
            </div>
          </div>
          <div class="rightView">
            <!-- <router-link
            :to="`/knowledge_base_detailed?id=${item.id}&pid=${id}&twoId=${subId}`"
            class="link"
            v-for="(item, index) in list"
            :key="index"
          >
            <h4>{{ item.title }}</h4>
            <p>{{ item.description }}</p>
          </router-link> -->
            <template v-if="list.length != 0">
              <div
                class="link"
                @click="
                  link(
                    `/knowledge_base_detailed?id=${item.id}&pid=${id}&twoId=${subId}`
                  )
                "
                v-for="(item, index) in list"
                :key="index"
              >
                <h4>{{ item.title }}</h4>
                <p>{{ item.description }}</p>
                <div class="tag-view">
                  <div
                    class="tag-item"
                    v-for="(tsg_item, tag_index) in item.tags"
                    :key="tag_index"
                    @click.stop="tag_click(tsg_item)"
                    :class="tags_id == tsg_item.id ? 'active' : ''"
                  >
                    {{ tsg_item.name }}
                  </div>
                </div>
              </div>
              <div class="pagination" v-if="list.length != 0">
                <el-pagination
                  layout="prev, pager, next"
                  :total="total"
                  @current-change="currentChange"
                ></el-pagination>
              </div>
            </template>
            <empty-data v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceAndSupportNav from "@/components/service-and-support/nav";
import ServiceAndSupportList from "@/components/service-and-support-list";
export default {
  name: "KnowledgeBaseList",
  components: {
    ServiceAndSupportNav,
    ServiceAndSupportList,
  },
  data() {
    return {
      id: this.$route.query.id || "",
      subId: this.$route.query.subId || "",
      keyword: this.$route.query.keyword || "",
      tabList: [],
      list: [],
      limit: 10,
      page: 1,
      total: 0,

      tags_id: this.$route.query.tags_id,
      tags_name: this.$route.query.tags_name,
    };
  },
  mounted() {
    this.knowledgeBaseListTabFun();
  },
  computed: {
    is_two() {
      return this.id == "" && this.subId == "";
    },
  },
  watch: {
    // $route(to, from) {
    //   console.log(to)
    //   this.id = to.query.id;
    //   this.subId = to.query.subId;
    //   to.query?.tags_id
    //     ? (this.tags_id = to.query.tags_id)
    //     : (this.tags_id = "");
    //   to.query?.tags_name
    //     ? (this.tags_name = to.query.tags_name)
    //     : (this.tags_name = "");
    //   this.knowledgeBaseListTabFun();
    //   location.reload()
    // },
    $route: {
      handler(to, from) {
        console.log(to);
        this.id = to.query.id;
        this.subId = to.query.subId;
        to.query?.tags_id
          ? (this.tags_id = to.query.tags_id)
          : (this.tags_id = "");
        to.query?.tags_name
          ? (this.tags_name = to.query.tags_name)
          : (this.tags_name = "");
        this.knowledgeBaseListTabFun();
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 标签点击
     */
    tag_click(item) {
      let query = {
        tags_id: item.id,
        tags_name: item.name,
        id: this.$route.query.id,
        subId: this.$route.query.subId || "",
        is_tag: "tag",
      };
      this.keyword = "";
      this.$router.push({ path: "/knowledge_base_list", query });
    },
    /**
     * 跳转
     */
    link(link) {
      this.$router.push(link);
    },
    /**
     * 搜索回调
     */
    search_change(e) {
      // console.log(this.$route.query);
      this.keyword = e;
      this.subId = "";
      this.id = "";
      const newUrl = "#" + this.$route.path + `?keyword=${e}`;
      window.history.replaceState("", "", newUrl);
      this.getData();
    },
    /**获取二级列表 */
    knowledgeBaseListTabFun() {
      this.$http.knowledgeCate({ parent_id: this.id }).then((res) => {
        if (res.code == 200) {
          this.tabList = res.data;
          if (res.data.length != 0) {
            if (this.$route.query.keyword) {
              this.subId = "";
              this.getData();
            } else {
              this.subId = this.subId || res.data[0].id;
              this.keyword = "";
              setTimeout(() => {
                this.getData(this.subId);
              });
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    /**二级列表点击 */
    tabClick(id) {
      this.subId = id;
      this.keyword = "";
      this.$router.push(`/knowledge_base_list?id=${this.id}&subId=${id}`);
      // this.getData();
    },
    /**
     * 分页
     */
    currentChange(page) {
      this.page = page;
      this.getData();
    },
    /**获取表格数据 */
    getData(id) {
      let data = {};
      if (this.$route.query.is_tag) {
        data = {
          search: this.keyword || "",
          limit: this.limit,
          page: this.page,
          tags_id: this.tags_id || "",
        };
      } else {
        data = {
          cate_id: this.subId,
          search: this.keyword || "",
          limit: this.limit,
          page: this.page,
          tags_id: this.tags_id || "",
        };
      }
      this.$http.knowledgeBaseList(data).then((res) => {
        if (res.code == 200) {
          this.list = res.data.data;
          this.page = res.data.current_page;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.pagination {
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
}
.main {
  width: 1400px;
  margin: 0 auto;
  @include respond-to(max1439) {
    width: 95%;
  }
  .mainView {
    border-top: 1px solid #e6e6e6;
    padding-top: 25px;
    @include respond-to(md) {
      padding-top: 15px;
    }
    .tag_name {
      margin-left: 248px;
      margin-right: 72px;
      color: #1a1a1a;
      font-size: 36px;
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 14px;
      font-weight: 500;
      @include respond-to(lg) {
        margin-left: 150px;
        font-size: 26px;
      }
      @include respond-to(md) {
        margin-left: 120px;
        font-size: 20px;
      }
    }
    .mainBox {
      display: flex;
      justify-content: space-between;
      padding: 25px 0 100px;
      @include respond-to(md) {
        padding: 10px 0 20px;
      }
      &.tag {
        padding-top: 14px;
      }
      .leftView {
        width: 248px;
        @include respond-to(lg) {
          width: 150px;
        }
        @include respond-to(md) {
          width: 120px;
        }
        .item {
          font-size: 16px;
          color: #1a1a1a;
          line-height: 2;
          @include respond-to(sm) {
            font-size: 12px;
          }
          span {
            cursor: pointer;
            &:hover {
              color: #0075c1;
            }
            &.active {
              color: #0075c1;
              pointer-events: none;
            }
          }
        }
        &.no {
          .item {
            cursor: not-allowed;
            span {
              color: #ccc;
              pointer-events: none;
              &:hover {
                color: #ccc;
              }
            }
          }
        }
      }
      .rightView {
        width: calc(100% - 248px);
        @include respond-to(lg) {
          width: calc(100% - 150px);
        }
        @include respond-to(md) {
          width: calc(100% - 120px);
        }
        .link {
          padding: 40px 0 14px;
          border-bottom: 1px solid #e6e6e6;
          display: block;
          cursor: pointer;
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            border-bottom: 0;
          }
          h4 {
            font-size: 18px;
            color: #333;
            font-weight: normal;
            margin-bottom: 10px;
            @include respond-to(md) {
              font-size: 16px;
            }
            @include respond-to(sm) {
              font-size: 14px;
            }
          }
          p {
            font-size: 16px;
            color: #666;
            line-height: 2;
            margin-bottom: 14px;
            @include respond-to(md) {
              font-size: 14px;
            }
            @include respond-to(sm) {
              font-size: 12px;
            }
          }
          &:hover {
            h4 {
              color: #0075c1;
            }
          }
          .tag-view {
            display: flex;
            flex-wrap: wrap;
            .tag-item {
              padding: 0 35px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #afb0ac;
              border-radius: 5px;
              font-size: 16px;
              color: #666;
              margin-right: 18px;
              // &.active {
              //   pointer-events: none;
              // }
              @include respond-to(md) {
                font-size: 14px;
                margin-right: 12px;
                padding: 0 25px;
                height: 28px;
              }
              @include respond-to(sm) {
                font-size: 12px;
                margin-right: 6px;
                padding: 0 10px;
                height: 24px;
              }
              &:hover {
                color: #0075c1;
                border-color: #0075c1;
              }
            }
          }
        }
      }
    }
  }
}
</style>